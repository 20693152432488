















































import mixins from 'vue-typed-mixins'
import DraftElement from '@/builder/sections/mixins/DraftElement'
import VCardWrapper from '@/builder/sections/section/calendars/components/VCardWrapper.vue'

export default mixins(DraftElement).extend({
  components: {
    VCardWrapper
  },
  props: {
    textColor: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    disableChanging: {
      type: Boolean,
      default: false
    },
    changeMode: {
      type: Boolean,
      default: false
    },
    classForViewRound: {
      type: String,
      default: ''
    },
    isExpanded: {
      type: Boolean,
      default: false
    }
  }
})
