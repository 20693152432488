var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:( _obj = {}, _obj[_vm.textColor] = true, _obj ),attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.selectLocationsTitle)}})])],1),_c('v-row',[_c('v-col',[_c('c-calendar-location-panel',{attrs:{"text-color":_vm.textColor,"color":_vm.backgroundColor,"class-for-view-round":_vm.classForViewRound}})],1)],1),_c('v-row',[_c('v-col',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.selectServicesTitle)}})])],1),_c('v-row',[(_vm.expandCollapseServicePanels)?_c('v-col',{class:{
                'text-center full-width': !_vm.$vuetify.breakpoint.smAndUp,
                'text-left': _vm.$vuetify.breakpoint.smAndUp
             },attrs:{"cols":"12"}},[_c('c-calendar-category-panel',{attrs:{"class-for-view-round":_vm.classForViewRound,"color":_vm.backgroundColor,"hide-booking-end-time":_vm.hideBookingEndTime,"change-mode":true,"text-color":_vm.textColor}})],1):_c('v-col',{staticClass:"text-h6",class:{
        'text-center full-width': _vm.isMobile,
        'text-left': !_vm.isMobile
      },attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$trans('category'))+" ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('c-calendar-service-panel',{attrs:{"text-color":_vm.textColor,"color":_vm.backgroundColor,"class-for-view-round":_vm.classForViewRound}})],1)],1),_c('v-row',[_c('v-col',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.selectEmployeesTitle)}})])],1),_c('v-row',[(_vm.isSettingRandomEmployeeSupported)?_c('v-col',{attrs:{"cols":"12"}},[_c('c-calendar-employee-panel',{attrs:{"text-color":_vm.textColor,"color":_vm.backgroundColor,"class-for-view-round":_vm.classForViewRound,"show-any-employees":""}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('c-calendar-employee-panel',{attrs:{"text-color":_vm.textColor,"color":_vm.backgroundColor,"class-for-view-round":_vm.classForViewRound}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }